import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { contactSection } from "../content/Common"
import ContactSection from "../components/common/ContactSection"
import InnerHeader from "../components/molecules/InnerHeader"
import FaqsSection from "../components/common/FaqsSection"
import {
  articles,
  faqs,
  helpAndSupportPage,
  needHelpSection,
} from "../content/HelpAndSupportPage"
import SupportCard from "../components/molecules/SupportCard"
import NeedHelpSection from "../components/pages/HelpAndSupport/NeedHelpSection"

const POSTS_PER_PAGE = 4

const HelpAndSupport = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    setCurrentPage(1)
  }, [searchQuery])

  var filteredArticles = searchQuery
    ? articles.filter(post =>
        post.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : articles

  const totalPages = Math.ceil(filteredArticles.length / POSTS_PER_PAGE)

  const paginatedArticles = filteredArticles.slice(
    (currentPage - 1) * POSTS_PER_PAGE,
    currentPage * POSTS_PER_PAGE
  )

  const handlePageChange = pageNumber => setCurrentPage(pageNumber)
  const handlePreviousPage = () =>
    currentPage > 1 && setCurrentPage(currentPage - 1)
  const handleNextPage = () =>
    currentPage < totalPages && setCurrentPage(currentPage + 1)

  return (
    <Layout>
      <Seo title="Help And Support" />

      <InnerHeader
        title={helpAndSupportPage.title}
        description={helpAndSupportPage.description}
        googleReviewText={helpAndSupportPage.googleReviewText}
        isSearchBox={true}
        formDetails={helpAndSupportPage.formDetails}
        setSearchQuery={setSearchQuery}
      />

      {filteredArticles.length > 0 ? (
        <div>
          <div className="cc-section--horizontal-space flex flex-col md:flex-row flex-wrap gap-[30px] justify-center pt-20 pb-20">
            {paginatedArticles.map((item, index) => (
              <SupportCard
                key={`support_` + index}
                data={item}
                componentEC={`w-[100%] md:w-[47%] lg:w-[31%]`}
              />
            ))}
          </div>

          <div className="flex justify-center mt-5 mb-28">
            <button
              type="button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
              className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                currentPage === 1 ? "text-[#979797]" : "text-[#000000]"
              } transition hover:bg-primary hover:text-primary`}
            >
              Previous
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index}
                type="button"
                onClick={() => handlePageChange(index + 1)}
                className={`mx-1 px-5 py-3 border border-[#BBBBBB] rounded ${
                  currentPage === index + 1
                    ? "bg-primary text-secondary"
                    : "text-[#000000]"
                } transition hover:bg-primary hover:text-primary`}
              >
                {index + 1}
              </button>
            ))}
            <button
              type="button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
              className={`mx-1 px-4 py-3 border border-[#BBBBBB] rounded ${
                currentPage === totalPages ? "text-[#979797]" : "text-[#000000]"
              } transition hover:bg-primary hover:text-primary`}
            >
              Next
            </button>
          </div>
        </div>
      ) : (
        <p className="cc-section--horizontal-space my-24">No Articles match your search query.</p>
      )}

      <NeedHelpSection data={needHelpSection} />

      <FaqsSection faqs={faqs} />

      <ContactSection data={contactSection} sectionTitleAlignment={`center`} />
    </Layout>
  )
}

export default HelpAndSupport
