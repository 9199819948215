import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import { postTypesSlug } from "../../content/Global"

const SupportCard = ({ data, componentEC }) => {
  return (
    <div
      className={`group rounded-[12px] bg-white px-6 py-8 transition hover:bg-[#1C5C4F] ${componentEC}`}
      style={{ boxShadow: `0px 0px 15px #e0e0e0` }}
    >
      <Link to={postTypesSlug.supportArticlesSlug + data.slug} href={postTypesSlug.supportArticlesSlug + data.slug}>
        <h2 className="text-[#081615] text-[20px] leading-[1.4em] font-[600] transition group-hover:text-[#D0F05A]">
          {data.title}
        </h2>
      </Link>
      {data.faqs.map((item, index) => (
        <div className="mt-4 text-[#777777] transition group-hover:text-white" key={"support_xard_" + index}>
          {item.question}
        </div>
      ))}

      <Link
        to={postTypesSlug.supportArticlesSlug + data.slug}
        href={postTypesSlug.supportArticlesSlug + data.slug}
        className="text-secondary text-[18px] font-[600] mt-16 inline-flex items-center gap-[10px] hover:underline transition group-hover:text-[#D0F05A]"
      >
        <span>View all</span> <FontAwesomeIcon icon={faArrowRight} />
      </Link>
    </div>
  )
}

export default SupportCard
